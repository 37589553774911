import {
    Box,
    Center,
    Flex,
    Group,
    MediaQuery,
    createStyles,
    useMantineTheme,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconChevronDown, IconX } from '@tabler/icons'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState, useCallback, useRef } from 'react'

import { SearchInput } from '~/components/home/SearchInput'
import { SubscriptionUpdates } from '~/components/home/SubscriptionUpdates'
import { MainMenu } from '~/components/layout/MainMenu'
import { MobileRedirectHeader } from '~/components/layout/MobileRedirectHeader'
import { getLibraryMenuItems } from '~/components/layout/constants'
import { DropdownMenu } from '~/components/shared/DropdownMenu'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'
import { useCurrentAuthState } from '~/context'
import { createBoard } from '~/endpoints/board'
import { ProfileSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'
import { useMobileOS } from '~/hooks/useMobileOS'
import { BANNER_HEIGHT, TOP_NAV_HEIGHT } from '~/utils/constants'
import { getRawColorValue } from '~/utils/helper'
import { captureSentryException } from '~/utils/sentry'
import { TopMenuItem } from '~/utils/types'

import { ServicesRedirectHeader } from './ServicesRedirectHeader'

const useStyle = createStyles(theme => ({
    mantineHeader: {
        borderBottomColor: 'transparent',
    },
    headerWithoutBg: {
        borderBottomColor: theme.colors.appBlack,
        zIndex: 1003,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 40px',
        backgroundColor: 'rgba(255, 255, 255, 0.16)',
        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            padding: '0 16px',
        },
    },
    navLink: {
        color: 'unset',
        cursor: 'pointer',
        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            display: 'none',
        },
        ':hover': {
            textDecoration: 'none',
        },
    },
}))

interface TopNavProps {
    isAuthenticated: boolean
    textColor: string
    isCreator: boolean
    profile?: ProfileSummaryType
    userEmail?: string | null
    headerCustomLeftSection?: ReactNode
    isTest?: boolean,
    setNavBarOffset: (offset: number) => void
}

export const TopNav: React.FC<TopNavProps> = ({
    isAuthenticated,
    isCreator,
    profile,
    userEmail,
    headerCustomLeftSection,
    isTest,
    setNavBarOffset,
}) => {
    const router = useRouter()
    const { classes } = useStyle()
    const theme = useMantineTheme()
    const { isMobileScreen, isSmallScreen } = useScreenSize()
    const [isCreatingBoard, setIsCreatingBoard] = useState(false)
    const color = 'appBlack.0'
    const rawColor = getRawColorValue(theme, color)
    const { clientInitialized } = useCurrentAuthState()
    const { isMobileOS } = useMobileOS()

    const isSeller = Boolean(profile?.stripeSellerId)
    const isGuidePage = router && router.pathname.includes('/view')
    const isEditorPage = router && router.pathname.endsWith('/edit')

    const [headerHeight, setHeaderHeight] = useState(TOP_NAV_HEIGHT + (isSeller ? BANNER_HEIGHT : 0))
    const [isValidPageForMobileBanner, setIsValidPageForMobileBanner] = useState(false)
    const [showMobileBanner, setShowMobileBanner] = useState(false)

    const [openSearchModal, setOpenSearchModal] = useState<boolean>(false)

    const [topNavOffset, setTopNavOffset] = useState(0)
    // const [prevScrollPos, setPrevScrollPos] = useState(0);
    const prevScrollPos = useRef(0);

    useEffect(() => {
        setHeaderHeight(TOP_NAV_HEIGHT + (isSeller ? BANNER_HEIGHT : 0))
    }, [isSeller])

    useEffect(() => {
        if (isValidPageForMobileBanner && isSmallScreen) {
            setShowMobileBanner(true)
        }
    }, [isMobileOS, isValidPageForMobileBanner, showMobileBanner])

    useEffect(() => {
        // dont show mobile banner for "/view" paths
        if (router && !router.pathname.includes('/view')) {
            setIsValidPageForMobileBanner(true)
        }
    }, [router])

    useEffect(() => {
        if (showMobileBanner) {
            setHeaderHeight(TOP_NAV_HEIGHT + BANNER_HEIGHT)
        }
    }, [showMobileBanner])

    const handleCreateNewGuide = async () => {
        setIsCreatingBoard(true)
        try {
            const newBoard = await createBoard()
            await router.push(`/guide/${newBoard.token}/edit`, undefined, { shallow: false })
        } catch (error) {
            captureSentryException(error)
            showNotification({
                id: 'action-failed',
                autoClose: 5000,
                icon: <IconX size={18} />,
                color: 'red',
                title: `Error ${error.code}`,
                message: error.message,
            })
        } finally {
            setIsCreatingBoard(false)
        }
    }

    const handleClickMenuItem = async (item: TopMenuItem) => {
        if (item.label == 'Create a Guide') {
            await handleCreateNewGuide()
        } else if (item.href) {
            await router.push(item.href)
        } else if (item.onClick) {
            item.onClick()
        }
    }

    const navBarCurrentHeight = TOP_NAV_HEIGHT + (isSeller || (!isGuidePage && isMobileScreen) ? BANNER_HEIGHT : 0);
    const handleScroll = useCallback((e: Event) => {
        if (!window.location.pathname.endsWith('/edit')) {
            const currentScrollPosition = Math.max(window.scrollY, 0); // in mobile safari this can be negative when scrolling up
            const scrollDelta = prevScrollPos.current - currentScrollPosition;
            const newOffset = Math.max(Math.min(topNavOffset + scrollDelta, 0), -1 * (navBarCurrentHeight));
            console.log("Handle Scroll", currentScrollPosition, scrollDelta, newOffset);
            setTopNavOffset(newOffset);
            prevScrollPos.current = currentScrollPosition;
            setNavBarOffset(headerHeight + newOffset);
        }
    }, [topNavOffset]);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [prevScrollPos.current]);

    return (<>
        <Box
            className={`h-[${TOP_NAV_HEIGHT + BANNER_HEIGHT - 1}px] sm:h-[${isSeller ? (TOP_NAV_HEIGHT + BANNER_HEIGHT - 1) : TOP_NAV_HEIGHT - 1}px] border-b border-solid border-black bg-white z-50 ${isEditorPage ? "relative" : "sticky"} top-0 w-screen`}
            style={{ transform: `translateY(${topNavOffset}px)` }}
        // height={headerHeight}
        >
            <Box h="100%">
                {isSeller ? <ServicesRedirectHeader /> : !isGuidePage ? <Group className="block sm:!hidden">
                    <MobileRedirectHeader />
                </Group> : null}
                <Box
                    className={classes.container}
                    h={TOP_NAV_HEIGHT - 1}
                >
                    {headerCustomLeftSection ? (
                        headerCustomLeftSection
                    ) : (
                        <Group
                            spacing="xl"
                            position="center"
                        >
                            <ThatchLink to="/">
                                <Center>
                                    <SvgIcon
                                        type={SvgIconType.LOGO}
                                        fill={color}
                                    />
                                </Center>
                            </ThatchLink>

                            <MediaQuery
                                smallerThan="sm"
                                styles={{ display: 'none' }}
                            >
                                <Box>
                                    <SearchInput
                                        isShownOnTopNav
                                        openSearch={openSearchModal}
                                        setOpenSearch={setOpenSearchModal}
                                    />
                                </Box>
                            </MediaQuery>
                        </Group>
                    )}
                    <Flex
                        gap={8}
                        align="center"
                    >
                        {isMobileScreen && (
                            <Flex
                                align="center"
                                justify="center"
                                sx={{
                                    borderRadius: '50%',
                                    border: '1px solid black',
                                    width: '40px',
                                    height: '40px',
                                }}
                                onClick={() => setOpenSearchModal(true)}
                            >
                                <SvgIcon type={SvgIconType.SMALL_SEARCH} />
                            </Flex>
                        )}
                        <Group spacing="xl">
                            {isAuthenticated && (
                                <DropdownMenu
                                    items={[...getLibraryMenuItems(isCreatingBoard)]}
                                    target={
                                        <Group
                                            data-testid="library-menu"
                                            spacing={4}
                                            className={classes.navLink}
                                        >
                                            <Typography
                                                variant="body3"
                                                color={color}
                                            >
                                                Your library
                                            </Typography>
                                            <IconChevronDown
                                                size={16}
                                                color={rawColor}
                                            />
                                        </Group>
                                    }
                                    handleClickItem={handleClickMenuItem}
                                />
                            )}
                            {!isCreator && (
                                <ThatchLink
                                    className={classes.navLink}
                                    to="/seller"
                                    prefetch={false}
                                >
                                    <Typography
                                        variant="body3"
                                        color={color}
                                    >
                                        Start Selling
                                    </Typography>
                                </ThatchLink>
                            )}
                            <MediaQuery
                                smallerThan="md"
                                styles={{ display: 'none' }}
                            >
                                <Box>{isAuthenticated && <SubscriptionUpdates color={color} />}</Box>
                            </MediaQuery>

                            <MainMenu
                                iconColor={color}
                                isAuthenticated={isAuthenticated}
                                userEmail={userEmail}
                                profile={profile}
                                onCreateNewGuide={handleCreateNewGuide}
                            />
                        </Group>
                    </Flex>
                </Box>
            </Box>
        </Box>
    </>)
}
