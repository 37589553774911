import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import type { DehydratedState } from '@tanstack/react-query'
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { NextPage, NextPageContext } from 'next/types'
import React, { ReactElement, ReactNode, useState } from 'react'
import { install } from 'resize-observer'
import { SpeedInsights } from '@vercel/speed-insights/next';
import { Analytics } from '@vercel/analytics/react';
import ErrorBoundary from '~/components/error/ErrorBoundary'
import { CustomFonts } from '~/components/layout/CustomFonts'
import { Layout } from '~/components/layout/Layout'
import ThatchPage from '~/components/page/ThatchPage'
import { SentryUserManager } from '~/components/reporting'
import { SegmentProvider } from '~/context'
import AuthStateProvider from '~/context/AuthStateProvider'
import SEO from '~/seo/default-seo.config'
import '~/styles/globals.css'
import thatchConfig from '~/thatch-config'
import { themeOverride } from '~/theme'
import initAuth from '~/utils/initAuth'
import { GTM_ID } from '~/utils/tag-manager'

type PageProps = {
  dehydratedState?: DehydratedState
  pageTitle?: string
}

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pageProps?: PageProps) => ReactNode
  isReactStrictModeDisabled?: boolean
}

type ExtendedAppProps<P = unknown> = {
  err?: NextPageContext['err']
  Component?: NextPageWithLayout
} & AppProps<P>

initAuth()

if (typeof window !== 'undefined' && !window.ResizeObserver) {
  // installing polyfill for ResizeObserver, to fix app crashing on Safari 12.1
  console.info('<< Installing ResizeObserver >>')
  install()
}

export default function MyApp({ Component, pageProps }: ExtendedAppProps<PageProps>) {
  const [queryClient] = useState(() => new QueryClient())

  const router = useRouter()
  const canonicalUrl = (thatchConfig.webappHost + (router.asPath === '/' ? '' : router.asPath))
    .split('?')[0]
    .replace(/\/index$/, '')

  const renderThatchPage = () => {
    return (
      <ThatchPage>
        <AuthStateProvider>
          {Component.getLayout ? (
            Component.getLayout(
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>,
              pageProps
            )
          ) : (
            <Layout>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </Layout>
          )}
          <SpeedInsights sampleRate={0.5}/>
          <Analytics />
          <SentryUserManager />
        </AuthStateProvider>
      </ThatchPage>
    )
  }

  return (
    <>
      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
      <DefaultSeo
        {...SEO}
        title={pageProps?.pageTitle || SEO.title}
        canonical={canonicalUrl}
      />
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <SegmentProvider>
            <MantineProvider
              withGlobalStyles
              withNormalizeCSS
              theme={themeOverride}
            >
              <CustomFonts />
              <Notifications
                position="top-center"
                zIndex={2077}
                limit={5}
              />
              {/* Editor library has issue with React.18 and Next.js when React strict mode is true */}
              {Component.isReactStrictModeDisabled ? (
                renderThatchPage()
              ) : (
                <React.StrictMode>{renderThatchPage()}</React.StrictMode>
              )}
            </MantineProvider>
          </SegmentProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </>
  )
}
